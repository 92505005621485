import { Block, Flexbox, Grid } from '@stage-ui/core'
import FlexboxTypes from '@stage-ui/core/layout/Flexbox/types'
import PageTitle from '@src/components/PageTitle'
import PageText from '@src/components/PageText'
import Cube from '@src/components/Cube'

import ScalingSectionArt from '../Components/ScalingSectionArt'
import ControlSectionArt from '../Components/ControlSectionArt'

const S06Scalling = (props: FlexboxTypes.Props) => {
  return (
    <Flexbox column {...props}>
      <PageTitle
        id="scaling"
        attributes={{
          'data-id': 'scaling',
        }}
        label="Преимущества"
        title="Масштабируемость"
      />
      <Cube
        scale={2}
        blur={5}
        hue={280}
        duration={20}
        style={{
          opacity: 0.6,
          position: 'absolute',
          right: '-10rem',
          top: '10rem',
        }}
      />
      <PageText>
        Растите столько сколько можете, система заточена под кратное <br />
        масштабирование без изменения производственных процессов.
      </PageText>

      <Grid
        templateColumns={['1fr 1fr', '1fr']}
        gap={['5.5rem', '2rem']}
        mt={['7.5rem', '2rem']}
      >
        <Block>
          <PageText title="Онлайн контроль на всех этапах производственного цикла">
            Контролируйте онлайн. Все этапы
            <br />
            производства связаны в единую
            <br />
            экосистему и постоянно актуализируются.
          </PageText>

          <PageText
            mt={['5.5rem', '2rem']}
            title="Внесение изменений в заказ на любом этапе сборки"
          >
            Корректируйте заказы и вносите изменения
            <br />
            на любом этапе сборке, без риска
            <br />
            их игнорирования звеньями процесса.
          </PageText>
        </Block>
        <ScalingSectionArt />
      </Grid>

      <Grid
        templateColumns={['1fr 1fr', '1fr']}
        gap={['5.5rem', '2rem']}
        mt={['12.5rem', '2rem']}
      >
        <ControlSectionArt display={['block', 'none']} />
        <Block>
          <PageText title="Аналитика недоступная прежде">
            Сможете повысить КПД сборщиков.
            <br />
            Отследить количество ошибок
            <br />
            и аккуратность фасовки.
            <br />
            Проанализировать количество
            <br />
            позиций в смене, пунктуальность
            <br />и многое другое.
          </PageText>

          <PageText
            mt={['5.5rem', '2rem']}
            title="Повышение качества предоставляемых услуг"
          >
            Исключите ошибки человеческого
            <br />
            фактора, пусть ваши клиенты
            <br />
            наслаждаются точностью исполнения
            <br />
            их заказов, как “в аптеке”.
          </PageText>
        </Block>
      </Grid>
    </Flexbox>
  )
}

export default S06Scalling
